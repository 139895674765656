// NOTE: These files should not have any more changes made to them
// before they are removed. If this poses a problem contact the front
// end engineering manager
define(['jquery', 'countdown', 'scriptOnComplete', 'siteObj', '$console'], function($, countdown, scriptOnComplete, siteObj, $console) {
  

  window.dispatchTimeout = true;

  var config = window.requirejs.s.contexts._.config.config.dispatchCountdown;

  /*
   fix for unit testing
   */
  if (!config) {
    config = {
      seperator: undefined
    };
  }

  var dispatchCountdown = {
    initDispatchCountdown: function() {

      if (siteObj.features !== undefined && siteObj.features.countdown !== undefined && !siteObj.features.countdown.disabled) {
        //Sets the rules for the countdown timer.
        // Build an Array from the time passed to a hidden input by the server.
        if (!siteObj.features || !siteObj.features.countdown || !siteObj.features.countdown.servertime) {
          $console.error('Cannot initialize dispatchcountdown. \'siteObj.features.countdown.servertime\' is not set');
          return;
        }
        var dateArray = siteObj.features.countdown.servertime.split('-');
        var year = parseInt(dateArray[0], 10);
        var month = parseInt(dateArray[1], 10) - 1; //This needs to have 1 removed because in javascript months go from 0 - 11
        var date = parseInt(dateArray[2], 10);
        var hour = parseInt(dateArray[3], 10);
        var minute = parseInt(dateArray[4], 10);
        var second = parseInt(dateArray[5], 10);
        var dayOfTheWeek = dateArray[6];
        var today = new Date(year, month, date, hour, minute, second, 0); // The time for today build from the server time
        var endHour = (typeof config !== 'undefined' && typeof config.endHour !== 'undefined')
          ? config.endHour : 21; // The end hour for the countdown
        var calendarEndHour = 0; // The end hour for the calendar countdown
        var calendarEndDate = date + 1; // The end date for the calendar countdown
        var endMinute = (typeof config !== 'undefined' && typeof config.endMinute !== 'undefined')
          ? config.endMinute : 0; // The end minute for the countdown
        var endSecond = (typeof config !== 'undefined' && typeof config.endSecond !== 'undefined')
          ? config.endSecond : 0; // The end second for the countdown
        var endMilli = (typeof config !== 'undefined' && typeof config.endMilli !== 'undefined')
          ? config.endMilli : 0; // The end milliseconds for the countdown
        var sundayDelivery = (typeof config !== 'undefined' && typeof config.sundayDelivery
        !== 'undefined')
          ? config.sundayDelivery : false; //Set if Sunday Delivery is Enabled

        // Return true if the user is in DST
        var isClientDst = function() {
          var jan = new Date(year, 0, 1);
          var now = new Date();

          var notDst = jan.getTimezoneOffset();
          var dst = now.getTimezoneOffset();

          return notDst !== dst;
        };

        // Start with atlantic time
        var AT = isClientDst() ? 180 : 240;
        var usTimeZones = [AT];

        var clientDate = new Date();
        var clientOffset = clientDate.getTimezoneOffset();

        // Create an array containing each US timezone, atlantic to samoa time
        for (var i = 1; i < 8; i++) {
          usTimeZones[i] = usTimeZones[i - 1] + 60;
        }

        // Check client's UTC offset, if they are in an NA timezone we set the timer
        // to 8pm and reduce by one hour for each time zone, going east to west
        for (var j = 0; j < usTimeZones.length; j++) {

          if (clientOffset === usTimeZones[j]) {
            endHour = 20 - j;

            break;
          }
        }

        // Time Zones GMT offsets
        if (siteObj.countryCode === 'fi') {
          endHour = endHour - 2;
          calendarEndHour = calendarEndHour - 2;
        }

        if (siteObj.countryCode === 'es' ||
          siteObj.countryCode === 'nl') {
          endHour = endHour + 1;
          calendarEndHour = calendarEndHour + 1;
        }

        if ((typeof config !== 'undefined' && typeof config.weekends !== 'undefined')
          && config.weekends === false && (dayOfTheWeek === 'Sunday' || dayOfTheWeek
          === 'Saturday')) {
          // If weekends are defined on the module config as false then turn off the timer
          endHour = 0;
        } else if (dayOfTheWeek === 'Saturday' && sundayDelivery) {
          // If it's a Saturday show the timer until 3pm
          endHour = 15;
        } else if (dayOfTheWeek === 'Sunday') {
          // If it's a Sunday show the timer until 2pm
          endHour = 14;
        }
        var endTime = new Date(year, month, date, endHour, endMinute, endSecond, endMilli);

        var calendarEndTime = new Date(year, month, calendarEndDate, calendarEndHour, endMinute, endSecond, endMilli);

        if (dayOfTheWeek === 'Saturday' && !sundayDelivery) { // Don't show the countdown on a Saturday
          endTime = null;
        }

        var $countdownTimerUSP = $('.countdown-timer-usp');
        var $countdownTimerPage = $('.countdown-timer-page');
        var $countdownTimerSiteWidget = $('.countdown-timer-widget');
        var $countdownTimerProduct = $('.countdown-timer-product');
        var $countdownTimerCalendar = $('[data-countdown-calendar-timer]');

        if ($countdownTimerUSP.length) {
          $countdownTimerUSP.countdownTimer({ // Initiate the countdown timer.
            parentHide: 'countdown-timer-usp-wrap',
            cutOffTime: endTime,
            serverTime: today,
            timerClass: '',
            showLabels: false,
            seperator: config.seperator || ':'
          });
        }

        if ($countdownTimerPage.length) {
          $countdownTimerPage.countdownTimer({ // Initiate the countdown timer.
            parentHide: 'countdown-timer-page-wrap',
            cutOffTime: endTime,
            serverTime: today,
            timerClass: '',
            showLabels: false,
            seperator: config.seperator || ':'
          });
        }

        if ($countdownTimerProduct.length) {
          $countdownTimerProduct.countdownTimer({ // Initiate the countdown timer.
            parentHide: 'same-day-dispatch-banner',
            cutOffTime: endTime,
            serverTime: today,
            timerClass: '',
            showLabels: true,
            seperator: '',
            labels: ['Days', 'hrs', 'mins', 'seconds']
          });
        }

        if ($countdownTimerSiteWidget.length) {
          $countdownTimerSiteWidget.countdownTimer({ // Initiate the countdown timer.
            parentHide: 'countdown-timer-site-widget',
            cutOffTime: endTime,
            serverTime: today,
            timerClass: 'kilo',
            showLabels: true,
            seperator: '',
            labels: ['Days', 'hrs', 'mins', 'secs']
          });
        }

        if ($countdownTimerCalendar.length) {
          $countdownTimerCalendar.countdownTimer({ // Initiate the countdown timer.
            parentHide: 'countdown-timer-calendar-wrap',
            cutOffTime: calendarEndTime,
            serverTime: today,
            timerClass: '',
            showLabels: true,
            seperator: config.seperator || ':',
            labels: ['Days', 'hrs', 'mins', 'secs']
          });
        }

      }

      scriptOnComplete.write('countdown');
    }
  };

  dispatchCountdown.initDispatchCountdown();
  return dispatchCountdown;
});
